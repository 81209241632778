/**
 * This file was automatically generated with 'graphql-code-generator' and should not be edited.
 * To regenerate this file update some *.graphql file and run `yarn codegen`
 *
 * See settings in project file: 'codegen.ts'
 */

/* eslint-disable */

import * as Types from '../../../types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type AdminJobPositionDetailFragment = {
  __typename?: 'AdminJobPosition';
  id: any;
  isPublished: boolean;
  occupationalHazards: Array<Types.JobPositionHazardChoices>;
  shiftWorkType: Types.JobPositionShiftWorkTypeChoices;
  shiftDurationHours: Types.JobPositionShiftDurationChoices;
  name: string;
  typeOfWork: string;
  czIsco: string;
  workEnvironmentCharacteristics: string;
  qualificationRequirements: string;
  responsibilities: string;
  extraordinaryWorkConditions: string;
  riskPreventionMeasures: string;
  maxRiskFactorValue?: Types.JobPositionRiskValueChoices | null;
};

export type AdminJobPositionDetailQueryVariables = Types.Exact<{
  adminJobPositionDetailId: Types.Scalars['AdminJobPositionID']['input'];
}>;

export type AdminJobPositionDetailQuery = {
  __typename?: 'Query';
  object:
    | {
        __typename?: 'AdminJobPosition';
        id: any;
        isPublished: boolean;
        occupationalHazards: Array<Types.JobPositionHazardChoices>;
        shiftWorkType: Types.JobPositionShiftWorkTypeChoices;
        shiftDurationHours: Types.JobPositionShiftDurationChoices;
        name: string;
        typeOfWork: string;
        czIsco: string;
        workEnvironmentCharacteristics: string;
        qualificationRequirements: string;
        responsibilities: string;
        extraordinaryWorkConditions: string;
        riskPreventionMeasures: string;
        maxRiskFactorValue?: Types.JobPositionRiskValueChoices | null;
      }
    | { __typename?: 'ObjectDoesNotExistResponse'; objectId?: string | null };
};

export const AdminJobPositionDetailFragmentDoc = gql`
  fragment AdminJobPositionDetail on AdminJobPosition {
    id
    isPublished
    occupationalHazards
    shiftWorkType
    shiftDurationHours
    name
    typeOfWork
    czIsco
    workEnvironmentCharacteristics
    qualificationRequirements
    responsibilities
    extraordinaryWorkConditions
    riskPreventionMeasures
    maxRiskFactorValue
  }
`;
export const AdminJobPositionDetailDocument = gql`
  query AdminJobPositionDetail($adminJobPositionDetailId: AdminJobPositionID!) {
    object: adminJobPositionDetail(id: $adminJobPositionDetailId) {
      ... on AdminJobPosition {
        ...AdminJobPositionDetail
      }
      ... on ObjectDoesNotExistResponse {
        objectId
      }
    }
  }
  ${AdminJobPositionDetailFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class AdminJobPositionDetailGQL extends Apollo.Query<
  AdminJobPositionDetailQuery,
  AdminJobPositionDetailQueryVariables
> {
  override document = AdminJobPositionDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
